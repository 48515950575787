<template>
   <div class="card_cont">
	   
	  <!-- <div class="rightDrew">
	   	  <a-anchor bounds='10px' :getCurrentAnchor='getCurrentAnchor'>
	   	    <a-anchor-link v-for="(item,index) in all_list" :href="'#'+item.type" :title="item.name" />
	   	  </a-anchor>
	   </div> -->
   	<div v-for="(item,key) in all_list" :id='item.type' :key='key'>
   		<div class="top_info" >
   			<div class="title_name">{{item.name}}</div>
   			<div class="title_btn" @click='go_editSettings(item.type)'  v-if="item.can_edit==1">
				<a-icon v-auth="'change'" type="form" :style="{color: '#397dee'}"/>
   				<span v-auth="'change'" class="cq_text_icon cq_color">编辑</span>
   			</div>
			<div class="title_btn" v-else>
				  <a-tooltip>
				    <template #title>即将上线，敬请期待</template>
				  <a-icon v-auth="'change'" type="form" :style="{color: '#c5c8ce'}"/>
				   <span v-auth="'change'" style="color: #c5c8ce;" class="cq_text_icon">编辑</span>
				  </a-tooltip>
				
			</div>
   		</div>
   		<div >
   			<p class="cq_text_tips">
				{{item.describe}}
   			</p>
   			<div>
				<a-form labelAlign='left' :label-col="labelColEdit" :wrapper-col="wrapperCol" >
				    <a-form-item v-for="(item,index) in item.children" :key='index' :label="item.name">
				        <p class="info"> {{item.value}}</p>
				    </a-form-item></a-form>
   			</div>
   		</div>
   	</div>
	
	

    </div>
</template>

<script>
    import {
      editCustomSettingCate,getCustomSettingInfo,
	  getCustomSettingDetail,changeCustomSettingStatic,
	  changeCustomSettingStatus,sortCustomSetting,addCustomSettingCate
    } from '@/api/enterprise'
    import options from '@/assets/json/city.js'

  
    import {
        message,
        TreeDataItem,
        TreeDragEvent,
        DropEvent,
        Modal
    } from 'ant-design-vue';

    export default {
        components: {
           
        },
        data() {
            return {
				all_list:[],
                editType:'add',
                company_id:'',
                 company_name:'',
                  merge_company:'',
                addressLitst: [],
                options,
                search_form: false,
				labelColEdit: {
				    span: 2
				},
				
				wrapperCol: {
				    span: 21
				},
               
            }

        },
        created() {
           
            this.getinfo()
        },
        methods: {
			getCurrentAnchor(){
			      return 'nature';
			    },
          go_editSettings(type){
			  
             this.$router.push({
                 path: '/Personneletting/' + type,
				 query:{
					 type:type
				 }
             })
          },
            del_open(id){
                this.company_id = id
                this.delVisible = true
            },
            merge_open(item){
                this.company_id = item.id
                  this.company_name = item.name
                this.mergeVisible = true
            },
            delOk(){
                delContractCompany({
                    data: {
                        company_id:this.company_id
                    }
                }).then(res => {
                   
                    this.delVisible = false
                    this.company_id = ''
                    this.$refs.list.get_list()
                })
            },
            changeAddress(e) {
                this.formState2.province = e[0]
                this.formState2.city = e[1]
                this.formState2.district = e[2]
            },
          
            get_info(id) {
                getContractCompanyDetail({
                    data: {company_id:id}
                }).then(res => {
                    this.company_id = id
                  
                  this.formState2.name=res.list.name
                  this.formState2.simple_name=res.list.simple_name
                this.formState2.juridical_person=res.list.juridical_person
                 this.formState2.province=res.list.register_province
                 this.formState2.city=res.list.register_city
                  this.formState2.contact_name=res.list.contact_name
                  this.formState2.district=res.list.register_district
                   this.formState2.address=res.list.address
                   this.addressLitst =[res.list.register_province,res.list.register_city,res.list.register_district]
                   this.editVisible = true
                   this.editType = 'edit'
                })
            },
            upInfo() {
                let option = {
                        name: this.formState2.name,
                        simple_name: this.formState2.simple_name,
                        juridical_person: this.formState2.juridical_person,
                        register_province: this.formState2.province,
                        register_city: this.formState2.city,
                        contact_name: this.formState2.contact_name,
                        register_district: this.formState2.district,
                        address: this.formState2.address,
                    }
                    if(this.editType=='add'){
                        saveContractCompany({
                            data: option
                        }).then(res => {
                          
                            this.editVisible = false
                            this.formState2 = {}
                            this.$refs.list.get_list()
                        
                        })
                    }else{
                        option.company_id = this.company_id
                        editContractCompany({
                            data: option
                        }).then(res => {
                          
                            this.editVisible = false
                            this.formState2 = {}
                            this.$refs.list.get_list()
                        })
                    }
            },
            getinfo() {
                getCustomSettingInfo().then(res=>{
                    this.all_list = res.data.list
                })
            },
            handle_submit() {

            }
        },

    }
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
    @import 'info.less';

    .table_list_body {
        margin: 0;
        padding: 0;
    }
    .check{
        // color: @cq_color;
        cursor: pointer;
    }
    .del{
        // color: @cq_error;
    }
	.card_cont{
		padding-right: 60px;
		position: relative;
		.rightDrew{
			right: 0px;
			top: 0px;
			width: 16px;
			  transition: width .5s;
			  position: absolute;
			  right: 0;
			  .ant-anchor{
				  background: rgba(255,255,255,.5);
			  }
		}
		.rightDrew:hover{
			width: 100px;
		}
	}
	
	.info {
		font-size: 14px;
		color: #333;
	}
	// .cq_text_tips{font-size: 12px;}
	
</style>
